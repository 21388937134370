export const contactForm = [
  {
    id: 1,
    name: 'name',
    placeholder: 'Full Name',
    type: 'text',
  },
  {
    id: 2,
    name: 'phone',
    placeholder: 'Phone',
    type: 'text',
  },
  {
    id: 3,
    name: 'email',
    placeholder: 'Email',
    type: 'email',
  },
  {
    id: 4,
    name: 'message',
    placeholder: 'Message',
    type: 'textarea',
  },
];
