import React, { useEffect, useState } from 'react';
import './Footer.scss';
import { MdArrowOutward } from 'react-icons/md';
import { Link, useSearchParams } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { navLinks } from '../../links';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [success, setIsSuccess] = useState(false);
  const [isValidEmail, setIsvalidEmail] = useState(true);
  const [query] = useSearchParams();
  const service = query.get('service');

  const isValidEmailSch = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,9}$/g;

  useEffect(() => {
    const validate = () => {
      if (email) {
        if (email.match(isValidEmailSch)) {
          setDisabled(false);
          setIsvalidEmail(true);
        } else {
          setIsvalidEmail(false);
          setDisabled(true);
        }
      } else {
        setIsvalidEmail(true);
        setDisabled(true);
      }
    };
    validate();
  }, [email]);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        'omniworks_no_reply',
        'template_ykdcs01',
        { email },
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      );
      setIsSuccess(true);
    } catch (err) {
      console.clear();
    }
  };
  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="left-footer">
          <p className="title"> Build your next project with us!</p>
          <div className="bottom-subscription">
            <div className="subscribe-input">
              <input
                type="text"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="button"
                onClick={submitForm}
                disabled={disabled}
              >
                <MdArrowOutward />
              </button>
              {!isValidEmail && (
                <span
                  style={{
                    color: '#ba000d',
                    paddingTop: '4px',
                    position: 'absolute',
                    top: '100%',
                  }}
                >
                  Please enter valid email
                </span>
              )}
            </div>

            {success && (
              <div className="success-alert">Success!, Thank you</div>
            )}

            <span className="copyright">
              Copyright @2023, <Link to="/">OmniWorks</Link> All Rights Reserved
            </span>
          </div>
        </div>
        <div className="right-footer">
          <div className="right-top">
            <Link
              to={`/contact-us?sectionId=main-contact-section&service=${
                service || 'full-stack'
              }`}
              className="explore-bttn"
            >
              <MdArrowOutward className="icon" />
              <span>Send Us a Message</span>
            </Link>
          </div>
          <div className="bottom-links">
            <div className="links-group">
              <span className="title">Get In Touch</span>
              <ul>
                <li>10428 Hammerly Blvd. Houston, TX 77043</li>
                <li>
                  <Link to="mailto:support@omniworks.solutions">
                    support@omniworks.solutions
                  </Link>
                </li>
                <li>
                  <Link to="tel:+18328593754">+1(832) 859-3754</Link>
                </li>
              </ul>
            </div>
            <div className="links-group" style={{ alignItems: 'center' }}>
              <span className="title" style={{ transform: 'translateX(-7px)' }}>
                Links
              </span>
              <ul>
                {navLinks.map((link) => (
                  <li key={link.id}>
                    <Link to={link.ref + `?service=${service || 'full-stack'}`}>
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* <div className="links-group">
              <ul style={{ paddingTop: '20px' }}>
                <li>
                  <Link to="/">Setting</Link>
                </li>
                <li>
                  <Link to="/">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
        <div className="mobile-link">
          <div className="links-group">
            <span className="title">Links</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
              {/* <li>
                <Link to="/">Privacy Policy</Link>
              </li> */}
              <li>
                <Link to="/apply-now">Careers</Link>
              </li>
            </ul>
          </div>
          {/* <div className="links-group">
            <ul style={{ paddingTop: '20px' }}>
              <li>
                <Link to="/">Setting</Link>
              </li>
              <li>
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/">Contact Us</Link>
              </li>
            </ul>
          </div> */}
        </div>
        <div className="subscribe-input-m">
          <input
            type="text"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="button" onClick={submitForm} disabled={disabled}>
            <MdArrowOutward />
          </button>
          {!isValidEmail && (
            <span
              style={{
                color: '#ba000d',
                opacity: '0.8',
                paddingTop: '4px',
                position: 'absolute',
                top: '100%',
                fontSize: '12px',
              }}
            >
              Please enter valid email
            </span>
          )}
        </div>
        {success && <div className="success-alert-m">Success!, Thank you</div>}
        <span className="copyright-m">
          Copyright @2023, <Link to="/">OmniWorks</Link> All Rights Reserved
        </span>
      </div>
    </div>
  );
};

export default Footer;
