const personalInfoForm = [
  {
    id: 1,
    name: 'full_name',
    label: 'Full Name',
    type: 'text',
  },
  {
    id: 2,
    name: 'email',
    label: 'Email',
    type: 'email',
  },
  {
    id: 3,
    name: 'phone',
    label: 'Phone',
    type: 'phone',
  },
];
const addressForm = [
  {
    id: 1,
    name: 'address',
    label: 'Address',
    type: 'text',
    size: '',
  },
  {
    id: 2,
    name: 'country',
    label: 'Country',
    type: 'select',
    size: 'short',
    default: 'US',
  },
  {
    id: 3,
    name: 'state',
    label: 'State',
    type: 'select',
    size: 'short',
  },
];

export { personalInfoForm, addressForm };
