import React, { useEffect, useState } from 'react';
import './SideBar.scss';
import { IoCloseSharp } from 'react-icons/io5';
import emailjs from '@emailjs/browser';
import { MdOutlineDomainVerification } from 'react-icons/md';

const SideBar = ({ setshowSideBar, showSideBar }) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [success, setIsSuccess] = useState(false);
  const isValidEmailSch = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,9}$/g;
  const [isValidEmail, setIsvalidEmail] = useState(true);

  useEffect(() => {
    const validate = () => {
      if (data.name && data.email && data.message && isValidEmail) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validate();
  }, [data]);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await emailjs.send(
        'omniworks_no_reply',
        'template_50e4sve',
        { ...data, form_name: 'Appointment Form' },
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      );
      setIsLoading(false);
      setIsSuccess(true);
    } catch (err) {
      setIsLoading(false);
      console.clear();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === 'email' && value.match(isValidEmailSch)) {
      setDisabled(false);
      setIsvalidEmail(true);
    } else {
      if (name === 'email') {
        setDisabled(true);
        setIsvalidEmail(false);
      }
    }
  };

  return (
    <div className={`sidebar ${showSideBar && 'show'}`}>
      <div className={`wrapper-sidebar ${showSideBar && 'show'}`}>
        <div className="top-div">
          <IoCloseSharp
            className="bttn"
            onClick={() => setshowSideBar(false)}
          />
        </div>
        {!success ? (
          <div className="form-div">
            <p className="title">Get Appointment</p>
            <input
              type="text"
              placeholder="Name"
              name="name"
              onChange={handleChange}
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
            {!isValidEmail && (
              <span
                style={{
                  color: '#ba000d',
                  paddingTop: '4px',
                  textAlign: 'left',
                  display: 'block',
                  width: '100%',
                  marginTop: '-20px',
                  fontSize: '14px',
                }}
              >
                Please enter valid email
              </span>
            )}
            <textarea
              placeholder="message"
              name="message"
              onChange={handleChange}
            />
            <button
              className="submit-bttn"
              onClick={submitForm}
              disabled={disabled | isLoading}
            >
              Submit
            </button>
          </div>
        ) : (
          <div className="success-div">
            <MdOutlineDomainVerification className="icon" />
            <span>Thank You</span>
            <p>
              Thanks for Choosing Omni Works, we will reach back to you as soon
              as possible
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBar;
