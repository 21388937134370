import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import logo from '../../assets/fulllogo_transparent.png';
import { Link, useSearchParams } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa6';
import { MdOutlineDashboardCustomize } from 'react-icons/md';
import { HiMenuAlt3 } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import SideBar from '../sideBar/SideBar';
import { navLinks } from '../../links';

const Navbar = () => {
  const [show, setShow] = useState(false);
  const [subShow, setSubShow] = useState(0);
  const [navFixed, setNavFixed] = useState(false);
  const [showSideBar, setshowSideBar] = useState(false);
  const [query] = useSearchParams();
  const service = query.get('service');

  const handleSubShow = (no) => {
    if (subShow === no) {
      setSubShow(0);
    } else {
      setSubShow(no);
    }
  };

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset === 0) {
        setNavFixed(false);
      } else {
        if (window.pageYOffset > 125) {
          setNavFixed(true);
        }
      }
    };
  }, []);

  return (
    <div className={`navbar ${navFixed ? 'fixed' : ''}`}>
      <SideBar setshowSideBar={setshowSideBar} showSideBar={showSideBar} />
      <div className="wrapper">
        <div className="logo-wrapper">
          <Link to={`/?service=${service || 'full-stack'}`}>
            <img src={logo} alt="logo" className="img-logo" />
          </Link>
        </div>
        <div className="links-wrapper">
          {navLinks.map((link) =>
            link?.innerLinks ? (
              <div className="link-hover-div" key={link.id}>
                <Link
                  to={link.ref + `?service=${service || 'full-stack'}`}
                  className="main-link"
                >
                  {link.name}
                  <span className="icon">
                    <FaPlus />
                  </span>
                </Link>
                <div className="sub-links">
                  {link.innerLinks.map((innerLink) => (
                    <Link
                      to={innerLink.ref + `?service=${service || 'full-stack'}`}
                      key={innerLink.id}
                    >
                      {innerLink.name}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
              <Link
                to={link.ref + `?service=${service || 'full-stack'}`}
                key={link.id}
              >
                {link.name}
              </Link>
            )
          )}

          <div className="bttns-wrapper">
            <button className="app-bttn" onClick={() => setshowSideBar(true)}>
              <MdOutlineDashboardCustomize />
            </button>
            <button className="menu-bttn" onClick={() => setShow(!show)}>
              {!show ? <HiMenuAlt3 /> : <IoMdClose />}
            </button>
          </div>
        </div>
      </div>
      <div className={`Nav-dropdown-menu ${show && 'show'}`}>
        {navLinks.map((link) =>
          link.innerLinks ? (
            <div className="link-hover-div">
              <Link
                to={link.ref + `?service=${service || 'full-stack'}`}
                className="main-link"
                onClick={() => handleSubShow(link.id)}
              >
                {link.name}
                <span className="icon">
                  <FaPlus />
                </span>
              </Link>
              <div className={`sub-links ${subShow === link.id && 'shown'}`}>
                {link.innerLinks.map((innerLink) => (
                  <Link
                    to={innerLink.ref + `?service=${service || 'full-stack'}`}
                    key={innerLink.id}
                  >
                    {innerLink.name}
                  </Link>
                ))}
              </div>
            </div>
          ) : (
            <Link
              to={link.ref + `?service=${service || 'full-stack'}`}
              key={link.id}
            >
              {link.name}
            </Link>
          )
        )}
      </div>
    </div>
  );
};

export default Navbar;
