import React, { useEffect, useState } from 'react';
import './Careers.scss';
import logo from '../../assets/fulllogo_transparent.png';
import {
  // FaLinkedinIn,
  // FaTwitter,
  // FaInstagram,
  FaCloudUploadAlt,
} from 'react-icons/fa';
// import { RiFacebookFill } from 'react-icons/ri';
import {
  IoIosArrowRoundBack,
  IoMdCheckmarkCircleOutline,
} from 'react-icons/io';
import emailjs from '@emailjs/browser';
import {
  Alert,
  Box,
  Button,
  FormControl,
  // IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import pageImg from '../../assets/info_graphic_1.svg';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { addressForm, personalInfoForm } from './CareersForms';
import { State, Country } from 'country-state-city';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../firebase';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Careers = () => {
  const [data, setData] = useState({});
  const [linksCount, setLinksCount] = useState([0]);
  const [success, setSuccess] = useState(false);
  const [countriesData, setCountriesData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const isValidEmailSch = /^[\w]+@([\w-]+\.)+[\w-]{2,9}$/g;
  const [isValidEmail, setIsvalidEmail] = useState(true);
  const [CVfile, setCVFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const service = searchParams.get('service');

  useEffect(() => {
    const uploadFileToFirebase = async () => {
      setIsUploading(true);
      const storageRef = ref(storage, CVfile.name);
      try {
        await uploadBytes(storageRef, CVfile);
        const fileUrl = await getDownloadURL(storageRef);
        setError(false);
        setData({ ...data, fileUrl });
        setIsUploading(false);
      } catch (err) {
        setIsUploading(false);
        setError(true);
      }
    };
    if (CVfile) {
      uploadFileToFirebase();
    }
  }, [CVfile]);

  //handle file Upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCVFile(file);
    }
  };

  // fetch all countries list
  useEffect(() => {
    setCountriesData(Country.getAllCountries());
  }, []);

  // Get state by country whenever it changes
  useEffect(() => {
    setData({ ...data, state: '' });
    setStateData(State.getStatesOfCountry(data?.country));
  }, [data.country]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === 'email' && value.match(isValidEmailSch)) {
      setDisabled(false);
      setIsvalidEmail(true);
    } else {
      if (name === 'email') {
        setDisabled(true);
        setIsvalidEmail(false);
      }
    }
  };

  // handles portfolio links changes and assigns id to each url to be passed and mapped in email.js template
  const handlePortfolioChanges = (e, index) => {
    const { value } = e.target;
    let protflioArray = [];
    if (!data.portfolio_links) {
      protflioArray.push({
        id: index + 1,
        url: value,
      });
    } else {
      protflioArray = data.portfolio_links;
      const target = protflioArray.find(
        (portfolio) => portfolio.id === index + 1
      );
      if (target) {
        target.url = value;
      } else {
        protflioArray.push({
          id: index + 1,
          url: value,
        });
      }
    }
    setData({ ...data, portfolio_links: protflioArray });
  };

  const addMoreLink = () => {
    setLinksCount([...linksCount, linksCount.length]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await emailjs.send(
        'omniworks_no_reply',
        'template_qdmdyxa',
        data,
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      );
      setError(false);
      setIsLoading(false);
      setSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      console.clear();
    }
  };

  useEffect(() => {
    const validate = () => {
      if (
        data.full_name &&
        data.phone &&
        data.email &&
        data.address &&
        data.country &&
        data.state &&
        data.experience &&
        data.fileUrl &&
        data.portfolio_links?.length >= 1 &&
        isValidEmail
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validate();
  }, [data, isValidEmail]);

  return (
    <div className="careers-page">
      {error && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            top: '16px',
            right: '16px',
            zIndex: '100',
          }}
        >
          Error! processing your request.
        </Alert>
      )}
      <div className="left-div">
        <div className="top-wrapper">
          <div className="img-wrapper">
            <img src={logo} alt="logo" />
          </div>
          {/* <div className="social-link">
            <IconButton className="social-button">
              <RiFacebookFill />
            </IconButton>
            <IconButton className="social-button">
              <FaLinkedinIn />
            </IconButton>
            <IconButton className="social-button">
              <FaTwitter />
            </IconButton>
            <IconButton className="social-button">
              <FaInstagram />
            </IconButton>
          </div> */}
        </div>
        <img src={pageImg} alt="apply-now" className="apply-now-img" />
        <h1>We are Hiring</h1>
        <p>
          Join our vibrant team of tech enthusiasts, where creativity thrives
          and innovation knows no bounds. At Omnieowkrs, we're not just building
          websites; we're shaping the future of digital experiences. Join us and
          be part of something extraordinary
        </p>
        <Link
          to={'/' + `?service=${service || 'full-stack'}`}
          className="career-sign"
        >
          © 2024 OmniWorks
        </Link>
      </div>
      <div className="right-div">
        <Link
          className="back-bttn"
          to={'/' + `?service=${service || 'full-stack'}`}
        >
          <IoIosArrowRoundBack style={{ fontSize: '28px' }} />
          Back
        </Link>
        {!success ? (
          <div className="form-container">
            <p className="form-title">Application Form</p>
            <div className="form-wrapper">
              <div className="form-section">
                <span className="section-title">Personal Information</span>
                <div className="inputs">
                  {personalInfoForm.map((form) => (
                    <>
                      <TextField
                        key={form.id}
                        label={form.label}
                        name={form.name}
                        variant="outlined"
                        className="input"
                        color="warning"
                        onChange={handleChange}
                        disabled={isUploading}
                      />
                      {!isValidEmail && form.type === 'email' && (
                        <span
                          style={{
                            color: '#ba000d',
                            marginTop: '-20px',
                            textAlign: 'left',
                            display: 'block',
                            width: '100%',
                            fontSize: '14px',
                          }}
                        >
                          Please enter valid email
                        </span>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="form-section">
                <span className="section-title">Address</span>
                <div className="inputs">
                  {addressForm.map((form) =>
                    form.type === 'text' ? (
                      <TextField
                        key={form.id}
                        label="Address"
                        variant="outlined"
                        className="input"
                        color="warning"
                        name={form.name}
                        onChange={handleChange}
                        disabled={isUploading}
                      />
                    ) : (
                      <FormControl
                        className="input short"
                        color="warning"
                        key={form.id}
                      >
                        <InputLabel id="demo-simple-select-label">
                          {form.label}
                        </InputLabel>
                        <Select
                          value={data[form?.name] ? data[form?.name] : ''}
                          label={form.label}
                          name={form.name}
                          onChange={handleChange}
                          disabled={isUploading}
                        >
                          {form.name === 'country'
                            ? countriesData.map((country, index) => (
                                <MenuItem value={country?.isoCode} key={index}>
                                  {country?.name}
                                </MenuItem>
                              ))
                            : stateData.map((state, index) => (
                                <MenuItem value={state?.name} key={index}>
                                  {state?.name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )
                  )}
                </div>
              </div>
              <div className="form-section">
                <span className="section-title">Portfolio</span>
                <div className="inputs">
                  {linksCount.map((links, index) => (
                    <TextField
                      key={index}
                      label="Portfolio Link ex: website"
                      variant="outlined"
                      className="input"
                      onChange={(e) => handlePortfolioChanges(e, index)}
                      color="warning"
                      disabled={isUploading}
                    />
                  ))}
                  <div className="bttn">
                    <Button className="add-bttn" onClick={addMoreLink}>
                      + Add more
                    </Button>
                  </div>
                </div>
              </div>
              <div className="form-section">
                <span className="section-title">Resume</span>
                <div className="inputs">
                  <div className="upload-cv">
                    {!isUploading ? (
                      <>
                        <span className="text">
                          {CVfile ? CVfile.name : 'No file chosen'}
                        </span>
                        <Button
                          component="label"
                          variant="contained"
                          className="upload-bttn"
                          startIcon={<FaCloudUploadAlt />}
                          disabled={data.file}
                        >
                          Upload CV
                          <VisuallyHiddenInput
                            type="file"
                            name="file"
                            onChange={handleFileUpload}
                          />
                        </Button>
                      </>
                    ) : (
                      <Box style={{ width: '100%', padding: '10px 0px ' }}>
                        <LinearProgress sx={{ width: '100% ' }} />
                        <p style={{ textAlign: 'center', marginTop: '6px' }}>
                          uploading in progress...
                        </p>
                      </Box>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-section">
                <span className="section-title">Experience</span>
                <div className="inputs">
                  <TextareaAutosize
                    minRows={4}
                    name="experience"
                    style={{ padding: '12px' }}
                    className="text-area"
                    placeholder="Tell us more about yourself..."
                    onChange={handleChange}
                    disabled={isUploading}
                  />
                </div>
              </div>
            </div>
            <Button
              className="submit-bttn"
              onClick={handleSubmit}
              disabled={disabled || isLoading}
            >
              Apply Now
            </Button>
          </div>
        ) : (
          <div className="success-component">
            <IoMdCheckmarkCircleOutline
              style={{ color: '#e2854a', fontSize: '90px' }}
            />
            <div className="text-wrapper">
              <p>Thank you for submitting the application form!</p>
              <span>
                We appreciate your interest in joining our team. We will review
                your application and be in touch soon.
              </span>
            </div>
            <Button className="backHome-bttn" onClick={() => navigate('/')}>
              Back to home
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Careers;
