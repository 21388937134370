import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Careers from './pages/Careers/Careers';
import ContacUs from './pages/ContactUs/ContactUs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes path="/">
          <Route index element={<Home />} />
          <Route path="apply-now" element={<Careers />} />
          <Route path="contact-us" element={<ContacUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
