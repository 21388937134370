import React, { useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import './ContactUs.scss';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { IoIosArrowRoundForward } from 'react-icons/io';
import emailjs from '@emailjs/browser';
import {
  MdMyLocation,
  MdOutlineArrowOutward,
  MdOutlineEdit,
  MdOutlineMailOutline,
} from 'react-icons/md';
import { FaPhone, FaPhoneFlip, FaRegUser } from 'react-icons/fa6';
// import { FaLinkedinIn, FaTwitter, FaInstagram } from 'react-icons/fa';
// import { RiFacebookFill } from 'react-icons/ri';
import {
  Alert,
  Button,
  Divider,
  FormControl,
  // IconButton,
  Input,
  InputAdornment,
  TextareaAutosize,
} from '@mui/material';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { contactForm } from './Form';
import calendlyLogo from '../../assets/calendlyLogoFull.png';
import { PopupModal } from 'react-calendly';

const ContacUs = () => {
  const [success, setSuccess] = useState(false);
  //gets the sectionId query to scroll to target sctionId
  const [searchParams] = useSearchParams();
  // to trigger useEffect whenever the location url key changed
  const location = useLocation();
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(true);
  const isValidEmailSch = /^[\w]+@([\w-]+\.)+[\w-]{2,9}$/g;
  const [isValidEmail, setIsvalidEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const service = searchParams.get('service');
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name === 'email' && value.match(isValidEmailSch)) {
      setDisabled(false);
      setIsvalidEmail(true);
    } else {
      if (name === 'email') {
        setDisabled(true);
        setIsvalidEmail(false);
      }
    }
  };
  // Handles submit form and setSuccess true
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await emailjs.send(
        'omniworks_no_reply',
        'template_50e4sve',
        { ...data, form_name: 'Contact-Us Form' },
        process.env.REACT_APP_EMAILJS_PUBLICKEY
      );
      setError(false);
      setIsLoading(false);
      setSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setError(true);
      console.clear();
    }
  };

  // Gets section id id avaible in the url query and scroll to it.
  useEffect(() => {
    const sectionId = searchParams.get('sectionId');
    const target = document.getElementById(sectionId);
    target?.scrollIntoView({ behavior: 'smooth' });
  }, [searchParams, location.key]);

  const renderIcons = (placeholder) => {
    switch (placeholder) {
      case 'Email':
        return (
          <MdOutlineMailOutline style={{ color: '#fff', fontSize: '18px' }} />
        );
      case 'Phone':
        return <FaPhone style={{ color: '#fff', fontSize: '18px' }} />;
      case 'Full Name':
        return <FaRegUser style={{ color: '#fff', fontSize: '18px' }} />;
      default:
        <MdOutlineMailOutline style={{ color: '#fff', fontSize: '18px' }} />;
    }
  };

  useEffect(() => {
    const validation = () => {
      if (data.name && data.phone && data.email && isValidEmail) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    };
    validation();
  }, [data, isValidEmail]);

  return (
    <div className="Contact-us">
      <Navbar />
      {openModal && (
        <PopupModal
          open={openModal}
          onModalClose={handleClose}
          url="https://calendly.com/omniworks-solutions"
          rootElement={document.getElementById('root')}
        />
      )}
      {error && (
        <Alert
          severity="error"
          style={{
            position: 'fixed',
            top: '90%',
            right: '16px',
            zIndex: '100',
          }}
        >
          Error! processing your request.
        </Alert>
      )}
      <div className="main-section">
        <div className="overlay-div">
          <h1>Contact Us</h1>
          <div className="links-wrapper">
            <Link
              to={'/' + `?service=${service || 'full-stack'}`}
              className="link"
            >
              Home
            </Link>{' '}
            <FaChevronRight style={{ fontSize: '18px' }} />
            <Link
              to={'/contact-us' + `?service=${service || 'full-stack'}`}
              className="link active"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
      <div id="main-contact-section">
        <div className="wrapper">
          <div className="left">
            <div className="top">
              <span className="sec-title">
                <IoIosArrowRoundForward className="icon" />
                Contact Us
              </span>
              <p className="heading">Ready To Work Your Next Projects</p>
            </div>
            <div className="bottom">
              <div className="box">
                <span className="box-title">
                  <MdMyLocation className="icon" />
                  Texas, USA
                </span>
                <span className="desc-txt">
                  10428 Hammerly Blvd. Houston, TX 77043
                </span>
                <div className="contact-info">
                  <Link
                    to="mailto:support@omniworks.solutions"
                    style={{ color: '#e2854a' }}
                  >
                    support@omniworks.solutions
                  </Link>
                  <div className="phone-div">
                    <span className="phone-icon">
                      <FaPhoneFlip />
                    </span>

                    <Link to="tel:+1(832) 859-3754" style={{ color: '#ffff' }}>
                      +1 (832) 859-3754
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="social-links">
              <p>Follow Us</p>
              <div className="social-wrapper">
                <IconButton className="social-button">
                  <RiFacebookFill />
                </IconButton>
                <IconButton className="social-button">
                  <FaLinkedinIn />
                </IconButton>
                <IconButton className="social-button">
                  <FaTwitter />
                </IconButton>
                <IconButton className="social-button">
                  <FaInstagram />
                </IconButton>
              </div>
            </div> */}
          </div>
          <div className="right">
            <div className="form-section">
              {!success ? (
                <div className="form-wrapper">
                  <div className="top">
                    <span className="sec-title">
                      <IoIosArrowRoundForward className="icon" />
                      Drop Us a Message
                    </span>
                    <p className="heading">Get Free Quote</p>
                  </div>
                  <div className="form-inputs">
                    {contactForm.map((form) =>
                      form.type !== 'textarea' ? (
                        <FormControl variant="standard" key={form.id}>
                          <Input
                            className="input-mui"
                            type={form.type}
                            name={form.name}
                            placeholder={form.placeholder}
                            onChange={handleChange}
                            startAdornment={
                              <InputAdornment position="start">
                                {renderIcons(form.placeholder)}
                              </InputAdornment>
                            }
                          />
                          {!isValidEmail && form.type === 'email' && (
                            <span
                              style={{
                                color: '#ba000d',
                                paddingTop: '4px',
                                textAlign: 'left',
                                display: 'block',
                                width: '100%',
                                fontSize: '14px',
                              }}
                            >
                              Please enter valid email
                            </span>
                          )}
                        </FormControl>
                      ) : (
                        <div className="textArea-container">
                          <TextareaAutosize
                            minRows={4}
                            name="message"
                            className="textarea-mui"
                            placeholder="Message"
                            onChange={handleChange}
                            key={form.id}
                          />
                          <span className="text-areaIcon">
                            <MdOutlineEdit
                              style={{ color: '#fff', fontSize: '18px' }}
                            />
                          </span>
                        </div>
                      )
                    )}

                    <div className="bttnsWrapper">
                      <Button
                        className="submit-bttn"
                        onClick={handleSubmit}
                        disabled={disabled || isLoading}
                      >
                        Send Us A Message
                        <MdOutlineArrowOutward style={{ fontSize: '20px' }} />
                      </Button>
                      <Divider sx={{ color: '#ffff' }}>or</Divider>

                      <Button
                        className="schedultBttn"
                        onClick={() => setOpenModal(true)}
                      >
                        Schedule Appointment{' '}
                        <img
                          src={calendlyLogo}
                          alt="calendlyLogo"
                          className="logoImg"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="success-component">
                  <IoMdCheckmarkCircleOutline
                    style={{ color: '#e2854a', fontSize: '90px' }}
                  />
                  <p>Thank you for reaching out!</p>
                  <span>
                    We've received your message and will get back to you
                    shortly.
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="map-section">
        <div className="maps-wrapper">
          <iframe
            title="location-google-maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3461.7800501470842!2d-95.55112152618892!3d29.81289812950726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c527750d3cc5%3A0xc2b185ed3c6fd459!2s10428%20Hammerly%20Blvd%2C%20Houston%2C%20TX%2077043%2C%20USA!5e0!3m2!1sen!2seg!4v1706961847898!5m2!1sen!2seg"
            width="100%"
            height="600"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContacUs;
