export const navLinks = [
  {
    id: 1,
    name: 'Home',
    ref: '/',
    innerLinks: null,
  },
  //   {
  //     id: 4,
  //     name: 'Contact Us',
  //     ref: '/contact-us',
  //     innerLinks: [
  //       {
  //         id: 41,
  //         name: 'service 1',
  //         ref: '/test',
  //       },
  //       {
  //         id: 42,
  //         name: 'service 1',
  //         ref: '/test',
  //       },
  //     ],
  //   },
  {
    id: 2,
    name: 'Contact Us',
    ref: '/contact-us',
    innerLinks: null,
  },
  {
    id: 3,
    name: 'Careers',
    ref: '/apply-now',
    innerLinks: [],
  },
];
